import React, { useEffect, useRef, useState } from "react";
import {
  addRetailerController,
  licenseFormsController,
  ownershipTypeController,
} from "../../services/retailerServices/retailerServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useButtonLoader from "../../hooks/useButtonLoader";
import {
  isValidAadharNumber,
  isValidEmail,
  isValidGst,
  isValidPANNumber,
  isValidPhoneNumber,
  isValidPincode,
} from "../../utility/formValidation";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";
import { dateFormatYear, dateTimeFormat } from "../../utility/utils";

function AddRetailer() {
  const navigate = useNavigate();
  const [panImage, setPanImage] = useState({});
  const [panImageName, setPanImageName] = useState();
  const [shopImageName, setShopImageName] = useState();
  const [licenceImageName, setLicenceImageName] = useState([]);
  const [shopImage, setShopImage] = useState({});
  const [ownershipList, setOwnershipList] = useState([]);
  const [licenseFormCheckBox, setLicenseFormCheckBox] = useState([]);
  const [buttonLoader, setButtonLoader] = useButtonLoader("Add", "Please Wait");
  const [errors, setErrors] = useState({});
  const [inputFieldValue, setInputFieldValue] = useState({
    name: "",
    email: "",
    aadharNumber: "",
    phoneNumber: "",
    secondaryMobileNumber: "",
    // shopLocation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    shopName: "",
    ownershipType: "",
    panBussiness: "",
    panName: "",
    gstNumber: "",
  });
  const [licenseForm, setLicenseForm] = useState([]);
  const [storeImage, setStoreImage] = useState([]);
  const [licenceDetail, setLicenceDetail] = useState([]);
  const [licenseFormValidation, setLicenseFormValidation] = useState({});

  //Input Field Validation.
  const formValidation = () => {
    const validImageFormat = ["image/jpeg", "image/png", "image/jpg"];
    let validationStatus = true;
    const error = {};
    if (!inputFieldValue.name) {
      error["name"] = "Enter your name *";
      validationStatus = false;
    }
    if (!inputFieldValue.phoneNumber) {
      error["phoneNumber"] = "Enter your Phone Number *";
      validationStatus = false;
    } else if (!isValidPhoneNumber(inputFieldValue.phoneNumber)) {
      error["phoneNumber"] = "Enter valid phone number *";
      validationStatus=false;
    }
    if (
      inputFieldValue.secondaryMobileNumber &&
      !isValidPhoneNumber(inputFieldValue.secondaryMobileNumber)
    ) {
      error["secondaryMobileNumber"] = "Enter valid phone number *";
      validationStatus = false;
    }
    if (inputFieldValue.email && !isValidEmail(inputFieldValue.email)) {
      error["email"] = "Enter valid email format";
      validationStatus = false;
    }
    if (
      inputFieldValue.aadharNumber &&
      !isValidAadharNumber(inputFieldValue.aadharNumber)
    ) {
      error["aadharNumber"] = "Enter valid Aadhar format *";
      validationStatus = false;
    }
    if (!inputFieldValue.ownershipType) {
      error["ownershipType"] = "Select ownership type *";
      validationStatus = false;
    }
    if (!inputFieldValue.shopName) {
      error["shopName"] = "Enter Shop Name *";
      validationStatus = false;
    }
    // if (!inputFieldValue.shopLocation) {
    //   error["shopLocation"] = "Enter shop location *";
    //   validationStatus = false;
    // }
    if (!inputFieldValue.address1) {
      error["address1"] = "Enter Address Line 1 *";
      validationStatus = false;
    }
    if (!inputFieldValue.city) {
      error["city"] = "Enter City *";
      validationStatus = false;
    }
    if (!inputFieldValue.state) {
      error["state"] = "Enter State *";
      validationStatus = false;
    }
    if (!inputFieldValue.pincode) {
      error["pincode"] = "Enter pincode *";
      validationStatus = false;
    } else if (!isValidPincode(inputFieldValue.pincode)) {
      error["pincode"] = "Enter valid pincode *";
      validationStatus = false;
    }
      if (inputFieldValue.gstNumber && !isValidGst(inputFieldValue.gstNumber)) {
        error["gst"] = "Enter vaild gstNumber *";
        validationStatus = false;
      }
    if (
      inputFieldValue.panName ||
      inputFieldValue.panBussiness ||
      panImage?.name
    ) {
      if (!inputFieldValue.panBussiness) {
        error["panBusiness"] = "Please enter PAN number *";
        validationStatus = false;
      } else if (!isValidPANNumber(inputFieldValue.panBussiness)) {
        error["panBusiness"] = "Please enter valid PAN number i.e AAAPL1234C *";

        validationStatus = false;
      }
      if (!inputFieldValue.panName) {
        error["panName"] = "Enter you PAN name *";
      }
      if (!panImage?.name) {
        error["panImage"] = "Please upload your PAN image *";
        validationStatus = false;
      }
      if (panImage?.name && panImage?.type) {
        if (!validImageFormat.includes(panImage?.type)) {
          error["panImage"] = "Please upload a valid image format *";
          validationStatus = false;
        }
      }
    }
    if (!shopImageName){
      error["shopImage"]="Please upload shop image"
      validationStatus = false;
    }
    else if (shopImage?.name && shopImage.type) {
      if (!validImageFormat.includes(shopImage.type)) {
        error["shopImage"] = "Please upload a valid image format *";
        validationStatus = false;
      }
    }

    const encounteredLicenseNumbers = new Set();

    for (let i = 0; i < licenceDetail.length; i++) {
      const License = licenceDetail[i];
      const currentStoreImage = storeImage[i];
      const { id, licenceNumber } = License;
      let errorField = {
        [id]: {},
      };

      if (!licenceNumber) {
        validationStatus = false;
        errorField[id]["licenceNumber"] = "Licence Number Required *";
      } else if (encounteredLicenseNumbers.has(licenceNumber)) {
        validationStatus = false;
        errorField[id]["licenceNumber"] = "Licence Number must be unique *";
      } else {
        encounteredLicenseNumbers.add(licenceNumber);
      }

      if (!License.expiryDate) {
        validationStatus = false;
        errorField[id]["expiryDate"] = "Expiry Date Required *";
      }

      if (
        !licenceImageName.find((imageName) => imageName.id === id)?.imageName
      ) {
        validationStatus = false;
        errorField[id]["storeImg"] = "Licence Image Required *";
      }

      setLicenseFormValidation((prevLicenseForm) => {
        const updatedLicenseValidationForm = {
          ...prevLicenseForm,
          ...errorField,
        };
        return updatedLicenseValidationForm;
      });
    }

    setErrors(error);
    return validationStatus;
  };

  // Fetching Ownership Type
  const fetchingOwnershipType = async () => {
    try {
      const response = await ownershipTypeController();
      // console.log(response);
      if (response?.code === 200) {
        setOwnershipList(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      // console.log(e.message);
    }
  };

  // Fetching license forms API
  const fetchingLicenseForms = async () => {
    try {
      const response = await licenseFormsController();
      console.log(response);
      if (response?.code === 200) {
        setLicenseFormCheckBox(response?.data);
      } else {
        console.log(response.message, response.code);
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching Add Retailer API
  const addRetailerHandler = async (e) => {
    e.preventDefault();
    const status = formValidation();
    // return false
    if (status) {
      try {
        setButtonLoader(true);
        const formData = new FormData();

        formData.append("name", inputFieldValue.name);
        formData.append("email", inputFieldValue.email);
        formData.append("aadharNumber", inputFieldValue.aadharNumber);
        formData.append("phoneNumber", inputFieldValue.phoneNumber);
        formData.append(
          "secondaryMobileNumber",
          inputFieldValue.secondaryMobileNumber
        );
        // formData.append("shopLocation", inputFieldValue.shopLocation);
        formData.append("address1", inputFieldValue.address1);
        formData.append("address2", inputFieldValue.address2);
        formData.append("city", inputFieldValue.city);
        formData.append("state", inputFieldValue.state);
        formData.append("pincode", inputFieldValue.pincode);
        formData.append("shopName", inputFieldValue.shopName);
        formData.append("ownershipType", inputFieldValue.ownershipType);
        formData.append("panBussiness", inputFieldValue.panBussiness);
        formData.append("panName", inputFieldValue.panName);
        formData.append("gstNumber", inputFieldValue.gstNumber);
        formData.append("shopImage", shopImage);
        formData.append("panImage", panImage);
        storeImage.forEach((image) => {
          formData.append("storeImage", image.storeImg);
        });

        formData.append(
          "licenceDetail",
          JSON.stringify(
            licenceDetail.map((items) => {
              return {
                licenceNumber: items.licenceNumber,
                expiryDate: dateFormatYear(items.expiryDate),
                approvedLicenceName: items.approvedLicenceName,
              };
            })
          )
        );

        const response = await addRetailerController(formData);
        if (response.code === 200) {
          toast.success(response.message);
          setButtonLoader(false);
          navigate(-1);
        } else {
          // toast.error(response.message);
          setButtonLoader(false);
        }
      } catch (e) {
        // console.log(e.message);
        setButtonLoader(false);
      }
    }
  };

  const inputFieldHandler = (e) => {
    const { name, value } = e.target;
    const valueType = typeof value;
    let sanitizedText;
    if (
      (name === "phoneNumber" ||
        name === "aadharNumber" ||
        name === "secondaryMobileNumber") &&
      valueType === "string"
    ) {
      sanitizedText = value.replace(/\D/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: sanitizedText });
    } else if (name === "email") {
      setInputFieldValue({ ...inputFieldValue, [name]: value.toLowerCase() });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  // Shop and Pan Image Handler
  const imageHandler = (e, imageName) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;

    if (files.length > 0) {
      if (imageFormat.includes(files[0]?.type)) {
        if (imageName === "panImage") {
          setPanImage(e.target.files[0]);
          setPanImageName(e.target.files[0]?.name);
        }
        if (imageName === "shopImage") {
          setShopImage(e.target.files[0]);
          setShopImageName(e.target.files[0]?.name);
        }
      } else {
        toast.error("Invalid Image Format");
        if (imageName === "panImage") {
          setPanImage({});
          setPanImageName("");
        }
        if (imageName === "shopImage") {
          setShopImage({});
          setShopImageName("");
        }
      }
    }
  };

  // handle checkbox check
  const handleLicenseForm = (e) => {
    const { name } = e.target;
    const lastChar = name.charAt(name.length - 1);
    // console.log(lastChar)
    if (licenceDetail.some((item) => item.id === name)) {
      console.log(lastChar, "lastChar-->");
      const updatedLicenceDetail = licenceDetail.filter(
        (item) => !item.id.endsWith(lastChar)
      );
      setLicenceDetail(updatedLicenceDetail);
      setLicenseForm((prevLicenseForm) =>
        prevLicenseForm.filter((item) => !item.endsWith(lastChar))
      );
      setStoreImage((prevImage) =>
        prevImage.filter((item) => !item.id.endsWith(lastChar))
      );
      setLicenceImageName((prevImage) =>
        prevImage.filter((item) => !item.id.endsWith(lastChar))
      );
    } else {
      let newArr = [];
      let mainArr = [];
      let storeImgArr = [];
      let licenceImgName = [];
      licenseFormCheckBox.forEach((item) => {
        console.log(item.licence, "");
        const licenceFormLastChar = item.licence.charAt(
          item.licence.length - 1
        );
        if (lastChar === licenceFormLastChar) {
          if (item.licence.includes(lastChar)) {
            const obj = {
              id: item.licence,
              licenceNumber: "",
              expiryDate: "",
              approvedLicenceName: item.licence,
            };
            const storeImgObj = {
              id: item.licence,
              storeImg: "",
            };
            const licence_Image_Name = {
              id: item.licence,
              imageName: "",
            };
            newArr.push(item.licence);
            mainArr.push(obj);
            storeImgArr.push(storeImgObj);
            licenceImgName.push(licence_Image_Name);
          }
        }
      });

      setLicenceDetail([...licenceDetail, ...mainArr]);
      setLicenseForm([...licenseForm, ...newArr]);
      setStoreImage([...storeImage, ...storeImgArr]);
      setLicenceImageName([...licenceImageName, ...licenceImgName]);
    }
  };

  const licenseInputFieldHandler = (e) => {
    const { name, value } = e.target;
    const [updateName, id] = name.split("_");

    setLicenceDetail((prevLicenceDetail) => {
      return prevLicenceDetail.map((item) => {
        if (item.id === id) {
          return { ...item, [updateName]: value };
        }
        return item;
      });
    });
  };

  // image handler
  const licenseInputImageHandler = (e, id) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;

    if (files.length > 0) {
      if (imageFormat.includes(files[0].type)) {
        setStoreImage((prevImages) => {
          const imageIndex = prevImages.findIndex((image) => image.id === id);

          if (imageIndex !== -1) {
            const updatedImages = [...prevImages];
            updatedImages[imageIndex] = {
              ...prevImages[imageIndex],
              storeImg: files[0],
            };
            return updatedImages;
          }

          return prevImages;
        });

        setLicenceImageName((prevImageNames) => {
          const imageIndex = prevImageNames.findIndex(
            (imageName) => imageName.id === id
          );

          if (imageIndex !== -1) {
            const updatedImageNames = [...prevImageNames];
            updatedImageNames[imageIndex] = {
              ...prevImageNames[imageIndex],
              imageName: files[0].name,
            };
            return updatedImageNames;
          }

          return prevImageNames;
        });
      } else {
        toast.error("Invalid Image Format");
      }
    }
  };

  console.log(licenceImageName, "LicenceImageName--->");

  // Licence Expiry Date Handler
  const licenceExpiryDateHandler = (date, id, objectId) => {
    setLicenceDetail((prevLicenceDetail) => {
      return prevLicenceDetail.map((item) => {
        if (item.id === objectId) {
          return { ...item, [id]: date };
        }
        return item;
      });
    });
  };

  const getSelectedImageUrl = (licenceId) => {
    console.log(licenceId, "LicenceId");
    const singleLicenceImageName = licenceImageName.find(
      (singleImageName) => singleImageName.id === licenceId
    );
    console.log(singleLicenceImageName, "SiongleLicenceImageName");
    return false;
    if (singleLicenceImageName && singleLicenceImageName.imageName) {
      return URL.createObjectURL(singleLicenceImageName.imageName);
    } else {
      return false;
    }
  };

  const deleteImageHandler = (name) => {
    if (name === "panImage") {
      setPanImage("");
      setPanImageName("");
    }
    if (name === "shopImage") {
      setShopImage("");
      setShopImageName("");
    }
  };

  // Delete Licence Image Handler
  const deleteLicenceImageHandler = (id) => {
    console.log(id, "idLicenceImage");
    const licenceImageToDelete = licenceImageName.find(
      (imageName) => imageName.id === id
    );

    console.log(licenceImageToDelete, "qeqqeq");
    console.log(licenceImageName, "LicenceImageName");

    // return false;

    if (licenceImageToDelete && licenceImageToDelete.imageName) {
      setLicenceImageName((prevLicenceImage) =>
        prevLicenceImage.map((item) =>
          item.id === id ? { ...item, imageName: "" } : item
        )
      );
    }
  };

  useEffect(() => {
    fetchingOwnershipType();
    fetchingLicenseForms();
  }, []);

  // const licenceImageRef = useRef();
  const licenceImageRefs = licenceDetail.map(() => React.createRef());
  const panRef = useRef();
  const shopRef = useRef();
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Add Retailer
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Personal Details</h4>
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Retailer Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter Name"
                              name="name"
                              value={inputFieldValue.name}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Aadhar Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={12}
                              placeholder="Enter Aadhar Number"
                              className="form-control"
                              name="aadharNumber"
                              value={inputFieldValue.aadharNumber}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.aadharNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Email Address
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={35}
                              name="email"
                              placeholder="Enter Email Address"
                              value={inputFieldValue.email}
                              oninput="convertToLowerCase(this)"
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxlength="10"
                              className="form-control"
                              name="phoneNumber"
                              placeholder="Enter Phone Number"
                              value={inputFieldValue.phoneNumber}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.phoneNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Secondary Phone Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxlength="10"
                              className="form-control"
                              name="secondaryMobileNumber"
                              placeholder="Enter Secondary Phone Number"
                              value={inputFieldValue.secondaryMobileNumber}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.secondaryMobileNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title">Business Details</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row text-start">
                          <label className="col-sm-3 col-form-label">
                            Store Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxlength="40"
                              className="form-control"
                              name="shopName"
                              placeholder="Enter Shop Name"
                              value={inputFieldValue?.shopName}
                              onChange={inputFieldHandler}
                            />
                              <span style={{ color: "red" }} className="error">
                              {errors.shopName}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Shop Location<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={60}
                              className="form-control"
                              placeholder="Enter Shop Location"
                              name="shopLocation"
                              value={inputFieldValue.shopLocation}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.shopLocation}
                            </span>
                          </div>
                        </div>
                      </div> */}
                       <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Address Line 1<span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="60"
                              placeholder="Enter Address Line 1"
                              name="address1"
                              value={
                                inputFieldValue.address1
                                  ? inputFieldValue.address1
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.address1}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Address Line 2
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="60"
                              placeholder="Enter Address Line 2"
                              name="address2"
                              value={
                                inputFieldValue.address2
                                  ? inputFieldValue.address2
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="60"
                              placeholder="Enter City"
                              name="city"
                              value={
                                inputFieldValue.city
                                  ? inputFieldValue.city
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.city}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxlength="60"
                              placeholder="Enter State"
                              name="state"
                              value={
                                inputFieldValue.state
                                  ? inputFieldValue.state
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.state}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            pinCode <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="number"
                              className="form-control"
                              maxlength="6"
                              placeholder="Enter pinCode"
                              name="pincode"
                              value={
                                inputFieldValue.pincode
                                  ? inputFieldValue.pincode
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.pincode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            GST Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="gstNumber"
                              placeholder="Enter GST Number"
                              value={inputFieldValue.gstNumber}
                              onChange={inputFieldHandler}
                            />
                             <span style={{ color: "red" }} className="error">
                              {errors.gst}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row text-start">
                          <label className="col-sm-3 col-form-label">
                            Ownership Type <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              onChange={inputFieldHandler}
                              name="ownershipType"
                            >
                              <option value="">Select Ownership Type</option>
                              {ownershipList.map(({ id, types }) => {
                                return <option value={id}>{types}</option>;
                              })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.ownershipType}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Number
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              className="form-control"
                              maxLength={12}
                              name="panBussiness"
                              placeholder="Enter PAN Number"
                              value={inputFieldValue?.panBussiness}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.panBusiness}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row text-start">
                          <label className="col-sm-3 col-form-label">
                            PAN Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={25}
                              className="form-control"
                              placeholder="Enter PAN Name"
                              name="panName"
                              value={inputFieldValue.panName}
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.panName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Image
                          </label>
                          <div className="col-sm-9">
                            <div className="form-group d-flex cstm_margin_remove justify-content-between align-items-center">
                              <div className="col-sm-12 text-end">
                                <div>
                                  <div className="file-wrap">
                                    <div
                                      className={`file-input ${
                                        !panImageName &&
                                        errors.panImage &&
                                        "is-invalid"
                                      }`}
                                      onClick={() => panRef.current?.click()}
                                    >
                                      <div className="choose">
                                        <span>Choose file</span>
                                      </div>
                                      <div className="file-name">
                                        <span>
                                          {panImageName && panImageName.slice(0,30) || "No file chosen"}
                                        </span>
                                      </div>
                                    </div>
                                    {!panImageName && errors.panImage && (
                                      <div className="invalid-feed text-start error">
                                        {errors.panImage}
                                      </div>
                                    )}
                                  </div>
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={(e) => {
                                      imageHandler(e, "panImage");
                                    }}
                                    placeholder="Select Image"
                                    className={`form-control `}
                                    accept="image/*"
                                    hidden
                                    ref={panRef}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-4 cstm_margin_remove">
                              {panImageName && (
                                <div className="cstm_img_set_retails cstm_image_retails_set">
                                  <i
                                    className="mdi mdi-close-circle-outline"
                                    onClick={() =>
                                      deleteImageHandler("panImage")
                                    }
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(panImage)}
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="banner"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Store Image <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9">
                            <div className="form-group d-flex cstm_margin_remove justify-content-between align-items-center">
                              <div className="col-sm-12 text-end">
                                <div>
                                  <div className="file-wrap">
                                    <div
                                      className={`file-input ${
                                        !shopImageName &&
                                        errors.shopImage &&
                                        "is-invalid"
                                      }`}
                                      onClick={() => shopRef.current?.click()}
                                    >
                                      <div className="choose">
                                        <span>Choose file</span>
                                      </div>
                                      <div className="file-name">
                                        <span>
                                          {shopImageName && shopImageName.slice(0,30) || "No file chosen"}
                                        </span>
                                      </div>
                                    </div>
                                    {!shopImageName && errors.shopImage && (
                                      <div className="invalid-feed text-start error">
                                        {errors.shopImage}
                                      </div>
                                    )}
                                  </div>
                                  <input
                                    type="file"
                                    name="image"
                                    onChange={(e) => {
                                      imageHandler(e, "shopImage");
                                    }}
                                    placeholder="Select Image"
                                    className={`form-control `}
                                    accept="image/*"
                                    hidden
                                    ref={shopRef}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-4 cstm_margin_remove">
                              {shopImageName && (
                                <div className="cstm_img_set_retails cstm_image_retails_set">
                                  <i
                                    className="mdi mdi-close-circle-outline"
                                    onClick={() =>
                                      deleteImageHandler("shopImage")
                                    }
                                  ></i>
                                  <img
                                    src={URL.createObjectURL(shopImage)}
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="banner"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4 className="card-title">Licence Details</h4>
                    <div className="row">
                      {licenseFormCheckBox.map((item) => {
                        return (
                          <React.Fragment>
                            <div className="col-4 text-start">
                              <FormControlLabel
                                label={item.licence}
                                control={
                                  <Checkbox
                                    name={item.licence}
                                    value={item.licence}
                                    checked={licenseForm.includes(item.licence)}
                                    onChange={handleLicenseForm}
                                  />
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="row">
                      {licenceDetail &&
                        licenceDetail.length > 0 &&
                        licenceDetail.map((item, index) => {
                          return (
                            <>
                              <div className="col-md-6">
                                <h4 className="my-4">{item.id}</h4>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Licence Number <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9 text-start">
                                      <input
                                        type="text"
                                        name={`licenceNumber_${item.id}`}
                                        value={item.licenceNumber}
                                        className="form-control"
                                        onChange={(e) =>
                                          licenseInputFieldHandler(e)
                                        }
                                      />
                                      <span
                                        style={{ color: "red" }}
                                        className="error text-start error"
                                      >
                                        {licenseFormValidation[item.id] &&
                                          licenseFormValidation[item.id]
                                            .licenceNumber}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Expiry Date <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9">
                                      <div className="react-datepicker-wrapper_retailer">
                                        <DatePicker
                                          placeholderText="Expiry Date"
                                          className="form-control"
                                          selected={item.expiryDate}
                                          value={item.expiryDate}
                                          showYearDropdown
                                          yearDropdownItemNumber={100}
                                          scrollableYearDropdown
                                          showMonthDropdown
                                          useShortMonthInDropdown
                                          minDate={Date.now()}
                                          onChange={(date) =>
                                            licenceExpiryDateHandler(
                                              date,
                                              "expiryDate",
                                              item.id
                                            )
                                          }
                                          dateFormat="dd/MM/yyyy"
                                          onChangeRaw={(e) =>
                                            e.preventDefault()
                                          }
                                        />
                                        <p
                                          style={{ color: "red" }}
                                          className="error text-start"
                                        >
                                          {licenseFormValidation[item.id] &&
                                            licenseFormValidation[item.id]
                                              .expiryDate}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">
                                      Licence Image<span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-9 text-start">
                                      <div className="form-group d-flex cstm_margin_remove justify-content-between align-items-center">
                                        <div className="col-sm-12">
                                          <div>
                                            <div className="file-wrap">
                                              <div
                                                className={`file-input ${
                                                  licenseFormValidation[
                                                    item.id
                                                  ] &&
                                                  licenseFormValidation[item.id]
                                                    .storeImg
                                                    ? "is-invalid"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  licenceImageRefs[
                                                    index
                                                  ]?.current?.click()
                                                }
                                              >
                                                <div className="choose">
                                                  <span>Choose file</span>
                                                </div>
                                                <div className="file-name">
                                                  <span>
                                                    {(licenceImageName[index] &&
                                                      licenceImageName[index]
                                                        .imageName) ||
                                                      "No file chosen"}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <input
                                              type="file"
                                              name="image"
                                              onChange={(e) => {
                                                licenseInputImageHandler(
                                                  e,
                                                  item.id
                                                );
                                              }}
                                              placeholder="Select Image"
                                              className={`form-control `}
                                              accept="image/*"
                                              hidden
                                              ref={licenceImageRefs[index]}
                                            />
                                          </div>

                                          <span
                                            style={{ color: "red" }}
                                            className="error"
                                          >
                                            {licenseFormValidation[item.id] &&
                                              licenseFormValidation[item.id]
                                                .storeImg}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="mb-4 cstm_margin_remove">
                                        {licenceImageName.find(
                                          (imageName) =>
                                            imageName.id === item.id
                                        )?.imageName !== "" && (
                                          <div className="cstm_img_set_retails cstm_image_retails_set">
                                            <i
                                              className="mdi mdi-close-circle-outline"
                                              onClick={() =>
                                                deleteLicenceImageHandler(
                                                  item.id
                                                )
                                              }
                                            ></i>
                                            <img
                                              src={
                                                licenceImageName.find(
                                                  (imageName) =>
                                                    imageName.id === item.id
                                                )?.imageName
                                                  ? URL.createObjectURL(
                                                      storeImage.find(
                                                        (image) =>
                                                          image.id === item.id
                                                      )?.storeImg
                                                    )
                                                  : ""
                                              }
                                              alt="retailer"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <button
                      onClick={(e) => {
                        addRetailerHandler(e);
                      }}
                      ref={buttonLoader}
                      type="submit"
                      className="btn btn-gradient-primary mb-2"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddRetailer;
