import React, { useState, useEffect, useLayoutEffect } from "react";
import { renderToString } from "react-dom/server";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import {
  changeProductStatusController,
  productListController,
  syncProductController,
} from "../../services/productServices/productListingController";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import {
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import ModalBox from "../../common/ModalBox";
import useButtonLoader from "../../hooks/useButtonLoader";
import TypingPlaceholder from "../../common/TypingPlaceholder";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";
import { updateInventoryController } from "../../services/recommendedServices/recommendedController";

const ProductManagement = () => {
  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();
  const [activePage, setActivePage] = useState(1);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const { permission } = usePermission();
  const [syncLoader, setSyncLoader] = useState(false);

  const fetchingProductList = async () => {
    try {
      show();
      // console.log(activePage);
      const response = await productListController(
        activePage ? activePage : "",
        limit ? limit : "",
        search ? search : "",
        filterByDate ? dateFormatYear(filterByDate) : ""
      );
      // console.log(response?.data?.rows);
      if (response?.code === 200) {
        hide();
        setTotalList(response?.data?.count);
        setProductList(response?.data?.rows);
      } else {
        toast.error(response?.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  const syncProducts = async () => {
    try {
      setSyncLoader(true);
      const response = await syncProductController();
      if (response?.code === 200) {
        fetchingProductList();
        toast.success("Product List Succesfully Synced");
      }
      setSyncLoader(false);
    } catch (e) {
      console.log(e.message);
      setSyncLoader(false);
    }
  };

  // Handle Product Status
  const handleProductStatus = async () => {
    try {
      setSubmitButton(true);
      console.log(activePage);
      const response = await changeProductStatusController(productId);
      console.log(response);
      if (response?.code === 200) {
        fetchingProductList();
        toast.success(response?.message);
        setSubmitButton(false);
        setStatusModal(false);
      } else {
        toast.error(response?.message);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

    // Sync Inventory
    const handleSyncInventory = async () => {
      try {
        show();
        const response = await updateInventoryController();
        if (response?.status === 200) {
          toast.success("Inventory synced successfully.");
          fetchingProductList(); 
        } else {
          toast.error(response?.message);
        }
        hide();
      } catch (e) {
        console.log(e.message);
        toast.error("Failed to sync inventory.");
        hide();
      }
    };
  

  // Clear filter
  const clearFilter = () => {
    setActivePage(1);
    setLimit(10);
    setSearch("");
    setFilterByDate("");
    fetchingProductList();
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    fetchingProductList();
  }, [activePage, limit, debounceValue, filterByDate]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Product Management </h3>
              <div className="button_wrap">
                <button
                  className="btn btn-outline-info cstm_btn_wrap_theme"
                  onClick={syncProducts}
                  disabled={syncLoader}
                  style={{ minWidth: '150px' }}
                >
                  {syncLoader ? (
                    <i className="mdi mdi-loading mdi-spin"></i>
                  ) : (
                    <span>Sync Products</span>
                  )}
                </button>
                <Link
                  to="#"
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={clearFilter}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Reset
                </Link>
                
              </div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                {/* <input
                  type="text"
                  className="form-control search"
                  placeholder={<TypingPlaceholder searchText="Search by product name" delay={100}/>}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by product name"
                  // {renderToString(
                  //   <TypingPlaceholder
                  //     initialText="Search by product name"
                  //     typingDelay={100}
                  //   />
                  // )}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-2 cstm_padding_set">
              <div className="datePicker_wrap cstm_date_picker">
                <DatePicker
                  showIcon
                  placeholderText="Filter By Date"
                  icon={true}
                  className="input date_picker cstm_btn_wrap_theme2"
                  selected={filterByDate}
                  value={filterByDate}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  onChange={(date) => setFilterByDate(date)}
                  dateFormat="dd/MM/yyyy"
                  maxDate={Date.now()}
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>
          {loader}
          {/* DropDown for approved Status */}

          <div className="row wrap_selectCstm">
            {/* {loader} */}
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Zoho Product Id</th>
                          <th>Product Name</th>
                          <th>Net Price</th>
                          <th>No. Of Stock</th>
                          <th>Created At</th>
                          {actionsChecker(permission, 3) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {productList && productList.length > 0 ? (
                          productList.map(
                            (
                              {
                                id,
                                productName,
                                netPrice,
                                noOfStock,
                                createdAt,
                                status,
                                productZohoId,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={productZohoId}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>{productZohoId || "---"}</td>
                                    <td>{productName}</td>
                                    <td>{netPrice === null ? '---' : `INR. ${netPrice}`}</td>
                                    <td>{noOfStock || "---"}</td>
                                    <td>{showDateFormatYear(createdAt)}</td>
                                    {actionsChecker(permission, 3) && (
                                      <td>
                                        <div className="action_button_wrap">
                                          {permissionChecker(
                                            permission,
                                            3,
                                            5
                                          ) && (
                                            <div className="switch_button">
                                              <FormControlLabel
                                                control={
                                                  <ToggleStatus
                                                    onClick={() => {
                                                      setStatusModal(true);
                                                      setProductId(id);
                                                    }}
                                                    sx={{ m: 1 }}
                                                    checked={status == 1}
                                                  />
                                                }
                                              />
                                            </div>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            3,
                                            3
                                          ) && (
                                            <Link
                                              to={`/product-management/view/${productZohoId}`}
                                              className="action_button"
                                            >
                                              <VisibilityIcon
                                                className="cstm_eye"
                                                color="secondary"
                                              />
                                            </Link>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            3,
                                            2
                                          ) && (
                                            <Link
                                              className="action_button"
                                              to={`/product-management/edit/${productZohoId}`}
                                            >
                                              <i class="mdi mdi-table-edit"></i>
                                            </Link>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>Products Not Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + productList.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Product Status Modal */}
        <ModalBox
          status={statusModal}
          onClose={() => {
            setStatusModal(false);
          }}
          title="Change Status"
          description="Are you sure you want to change the status of this product?"
          action={handleProductStatus}
          uploadButtonElement={submitButton}
        />
        {/*  */}
      </div>
    </React.Fragment>
  );
};

export default ProductManagement;
