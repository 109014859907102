import React, { useEffect, useState } from "react";
import { helpAndSupportListController } from "../../services/helpAndSupportServices/helpAndSupportController";
import { Link } from "react-router-dom";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import ModalBox from "../../common/ModalBox";
import { MultiSelect } from "react-multi-select-component";
import useButtonLoader from "../../hooks/useButtonLoader";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { red } from "@mui/material/colors";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import {
  deleteOfferController,
  offersListingController,
} from "../../services/offersServices/offersManagementController";
import {
  addRecommendedProductController,
  deleteRecommendedController,
  productListController,
  recommendedProductListController,
} from "../../services/recommendedServices/recommendedController";
import {
  changePointStatusController,
  pointsListingController,
} from "../../services/pointsManagementServices/pointsManagementController";
import { usePermission } from "../../context/create_Context";
import { permissionChecker } from "../../config/roleAccess";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

function RecommendationManagement() {
  const [productList, setProductList] = useState([]);
  const [recommendationProductId, setRecommendationProductId] = useState(null);
  const [recommendedStatus, setRecommendedStatus] = useState(null);
  const [selectProducts, setSelectProducts] = useState([]);
  const [recommendationProducts, setRecommendationProducts] = useState([]);
  const [offersList, setOffersList] = useState([]);
  const [search, setSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [totalList, setTotalList] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [recommendedId, setRecommendedId] = useState();
  const [updateFilterCategoryName, setUpdateFilterCategoryName] = useState("");
  const [startNumber, setStartNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState("");
  const [buttonLoader, setButtonLoader] = useButtonLoader();
  const [limit, setLimit] = useState(10);
  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [showDescription, setShowDescription] = useState("");
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [recommendId, setRecommendId] = useState(null);
  const [selectLoader, setSelectLoader] = useState(false);
  const { permission } = usePermission();

  const fetchRecommendationList = async () => {
    try {
      show();
      const response = await recommendedProductListController();
      // console.log(response);
      if (response?.data?.code === 200) {
        hide();
        setRecommendationProducts(response?.data?.data?.list);
        setRecommendedStatus(response?.data?.data?.recommandProduct.status);
        // setTotalList(response?.data?.data?.count);
      } else {
        toast.error(response.response.data.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  //fetching for getting recommended id
  const fetchingPointsListing = async () => {
    try {
      const response = await pointsListingController();
      // console.log(response);
      if (response.data?.code === 200) {
        const responseData = response?.data?.data;
        setRecommendationProductId(responseData[4].id);
        // console.log(responseData[4].id, "--->");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Add Select Products Validation
  const Validation = () => {
    let validationStatus = true;
    if (selectProducts.length <= 0) {
      setErrors("Select Products");
      validationStatus = false;
    }
    return validationStatus;
  };

  // Add Products Recommendation Handler
  const addProductsRecommendationHandler = async () => {
    const status = Validation();
    if (status) {
      try {
        setSubmitButton(true);
        const productId = selectProducts.map(({ value }) => value);
        const response = await addRecommendedProductController(productId);
        if (response?.data?.code === 200) {
          toast.success(response?.data?.message);
          setAddModal(false);
          setSubmitButton(false);
          setErrors("");
          fetchRecommendationList();
          fetchingPointsListing();
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setAddModal(false);
          setSubmitButton(false);
          setErrors("");
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  // Fetch Products List
  const fetchProductList = async () => {
    try {
      setSelectLoader(true);
      const response = await productListController();
      if (response?.data?.code === 200) {
        setProductList(response?.data?.data);
        setSelectLoader(false);
      } else {
        toast.error(response.response.data.message || response?.data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Change Status Handler
  const changeStatusHandler = async () => {
    try {
      const response = await changePointStatusController(
        recommendationProductId
      );
      if (response?.data?.code === 200) {
        toast.success(response?.data?.message);
        fetchRecommendationList();
        setStatusModal(false);
      } else {
        toast.error(response.response.data.message || response?.data?.message);
        setStatusModal(false);
        fetchRecommendationList();
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching Delete Recommended
  const deleteRecommendedHandler = async (e) => {
    e.preventDefault();
    setSubmitButton(true);
    try {
      const response = await deleteRecommendedController(recommendId);
      if (response?.data?.code === 200) {
        setDeleteModal(false);
        toast.success(response.data?.message);
        fetchRecommendationList();
        setSubmitButton(false);
      } else {
        toast.error(
          response?.response?.data?.message || response?.data?.message
        );
        setDeleteModal(false);
        setSubmitButton(false);
      }
    } catch (e) {
      console.log(e.message);
      setSubmitButton(false);
    }
  };

  // options for multiSelect
  const options = productList.map(({ productName, id }) => ({
    label: productName,
    value: id,
  }));

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  useEffect(() => {
    fetchRecommendationList();
    fetchProductList();
    fetchingPointsListing();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title">Recommendation Management</h3>
              {permissionChecker(permission, 14, 1) && (
                <div className="button_wrap">
                  <Link
                    to="#"
                    className="btn btn-btn btn-gradient-primary button"
                    onClick={() => setAddModal(true)}
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i> Add New
                    Recommendation
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="row wrap_selectCstm my-4">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-4 cstm_padding_set">
              {/* <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search by Coupon Code"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div> */}
            </div>
            {permissionChecker(permission, 13, 5) && (
              <div className="col-lg-7 text-end ">
                <FormControlLabel
                  control={
                    <ToggleStatus
                      onClick={() => {
                        setStatusModal(true);
                      }}
                      sx={{ m: 1, float: "left" }}
                      checked={recommendedStatus == 1}
                    />
                  }
                />
              </div>
            )}
          </div>
          {loader}

          <div className="row wrap_selectCstm">
            <div className="col-lg-12 cstm_padding_set grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product Name</th>
                          <th>Net Price</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader}
                        {recommendationProducts &&
                        recommendationProducts.length > 0
                          ? recommendationProducts.map(
                              ({ id, isChecked, productDetails }, index) => {
                                return (
                                  <React.Fragment key={id}>
                                    <tr>
                                      <td>
                                        {(activePage - 1) * limit + ++index}
                                      </td>
                                      <td>{productDetails?.productName}</td>
                                      <td>
                                        {` INR.  ${
                                          productDetails?.netPrice || "---"
                                        }`}
                                      </td>
                                      <td>
                                        {productDetails?.status === 1
                                          ? "Active"
                                          : "Inactive"}
                                      </td>
                                      <td>
                                        {permissionChecker(
                                          permission,
                                          14,
                                          4
                                        ) && (
                                          <Link className="action_button">
                                            <DeleteRoundedIcon
                                              sx={{ color: red[500] }}
                                              onClick={() => {
                                                setRecommendId(id);
                                                setDeleteModal(true);
                                              }}
                                            />
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + offersList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
          status={addModal}
          onClose={() => {
            setAddModal(false);
            setSelectProducts([]);
            setErrors("");
          }}
          title={
            <div className="items-center">Add Recommendation Products</div>
          }
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div className="text-center">
              <h4>Select Products</h4>
            </div>
            <MultiSelect
              options={options}
              value={selectProducts}
              onChange={setSelectProducts}
              labelledBy="Select"
              className="mb-2"
              isLoading={selectLoader}
            />
            <span style={{ color: "red" }} className="error">
              {errors}
            </span>

            <div className="d-flex justify-content-center align-items-center">
              <button
                class="btn btn-light"
                onClick={(e) => {
                  setAddModal(false);
                  e.preventDefault();
                  setErrors("");
                  setSelectProducts([]);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                ref={submitButton}
                class="btn btn-gradient-primary me-2"
                onClick={(e) => {
                  addProductsRecommendationHandler(e);
                }}
              >
                Add
              </button>
            </div>
          </React.Fragment>
        </ModalBox>

        {/* Status Change Modal */}
        <ModalBox
          status={statusModal}
          onClose={() => setStatusModal(false)}
          title={"Change Status"}
          description={"Are you sure you want to change the status?"}
          action={changeStatusHandler}
          uploadButtonElement={buttonLoader}
        />

        {/* Delete Recommended*/}
        <ModalBox
          status={deleteModal}
          onClose={() => setDeleteModal(false)}
          title="Delete Offer"
          description={"Are you sure you want to delete?"}
          action={deleteRecommendedHandler}
          uploadButtonElement={submitButton}
        />
      </div>
    </React.Fragment>
  );
}

export default RecommendationManagement;
