import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorListController } from "../../services/vendorServices/vendorServices";
import { toast } from "react-toastify";
import {
  deleteProductImageController,
  productDetailsController,
  updateProductController,
} from "../../services/productServices/productListingController";
import useButtonLoader from "../../hooks/useButtonLoader";
import { fetchProductByIdController } from "../../services/productServices/productListingController";

function EditProduct() {
  const [multipleproductImage, setMultipleProductImage] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const { productId } = useParams();
  const [images, setImages] = useState([]);
  const [submitButton, setSubmitButton] = useButtonLoader(
    "Update",
    "Please wait..."
  );
  const navigate = useNavigate();
  const uploadProductImage = useRef();

  //  Fetching Product Details API
  const fetchingProductDetail = async () => {
    try {
      const response = await productDetailsController(productId);
      if (response?.code === 200) {
        setInputFieldValue(response?.data);
        setImages(response?.data?.productImage);
      } else {
        toast.error(response?.message || "Internal Server Error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchProductDetails = async () => {
    try {
      const response = await fetchProductByIdController(productId);
      if (response?.success) {
        const productData = {
          productName: response.product.name || "",
          netPrice: response.product.rate || "",
          noOfStock: response.product.actual_available_for_sale_stock || "",
          sku: response.product.sku || "",
          vendorId: response.product.vendorId || "",
          dimestion: response.product.custom_field_hash.cf_packaging || "",
          margin:
            response.product.custom_field_hash.cf_margin_unformatted || "",
          mrp: response.product.custom_field_hash.cf_mrp_unformatted || "",
          productImage: response.product.image_name || "",
          units: response.product.unit || "",
          noOfUnitPerPack:
            response.product.custom_field_hash.cf_no_of_units_per_pack ||
            response.product.custom_field_hash
              .cf_no_of_units_per_pack_unformatted ||
            "",
          smallestUnit:
            response.product.custom_field_hash.cf_smallest_unit || "",
          productComposition: response.product.description || "",
        };
        // Check if noOfUnitPerPack is 0 and set it to an empty string if so
        if (productData.noOfUnitPerPack === "0") {
          productData.noOfUnitPerPack = "";
        }
        toast.success("Product details fetched successfully!");
        setInputFieldValue(productData);
      } else {
        console.error(response.message || "Product not found");
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Function for checking alphanumeric
  function containsAlphaNumeric(input) {
    // Check if the input contains at least one alphabetic character and one numeric character
    return /[a-zA-Z]/.test(input) && /[0-9]/.test(input);
  }

  // Margin calculation
  const calculatedMargin =
    inputFieldValue?.mrp && inputFieldValue?.netPrice
      ? parseFloat(inputFieldValue?.mrp) - parseFloat(inputFieldValue?.netPrice)
      : "";

  // Input Field Validation
  const inputFieldValidation = () => {
    let error = {};
    const validImageFormat = ["image/jpg", "image/jpeg", "image/png"];
    let validationStatus = true;

    if (!inputFieldValue?.productName) {
      error["productName"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.netPrice) {
      error["netPrice"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.noOfStock) {
      error["noOfStock"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.smallestUnit) {
      error["smallestUnit"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.vendorId) {
      error["vendorId"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.noOfUnitPerPack) {
      error["noOfUnitPerPack"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.sku) {
      error["sku"] = "Required*";
      validationStatus = false;
    }
    if (inputFieldValue.sku && !containsAlphaNumeric(inputFieldValue.sku)) {
      error["sku"] = "SKU should contain both Alphabet & Numerical*";
      validationStatus = false;
    }
    if (!inputFieldValue.dimestion) {
      error["dimestion"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.margin) {
      error["margin"] = "Required*";
      validationStatus = false;
    }
    if (!inputFieldValue.mrp) {
      error["mrp"] = "Required*";
      validationStatus = false;
    }
    if (!images[0] && multipleproductImage.length === 0) {
      error["productImage"] = "Required*";
      validationStatus = false;
    } else {
      multipleproductImage.forEach((image, index) => {
        if (!validImageFormat.includes(image.type)) {
          error["productImage"] = `Invalid image format at ${index + 1}`;
          validationStatus = false;
        }
      });
    }
    setErrors(error);
    return validationStatus;
  };

  //Fetching Vendor List
  const fetchingVendorList = async () => {
    const response = await vendorListController();
    // console.log(response);
    if (response?.code === 200) {
      setVendorList(response?.data?.rows);
    } else {
      toast.error(response?.message);
    }
  };

  // multiple image handler function
  const multipeImageHandler = (e) => {
    const imageFormat = ["image/jpg", "image/jpeg", "image/png"];
    const { files } = e.target;
    const maxSize = 1024 * 1024;

    if (files.length > 0) {
      let validFiles = true;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error("Image size should not be larger than 1MB");
          validFiles = false;
          break;
        }
        if (!imageFormat.includes(files[i].type)) {
          toast.error("Invalid image format");
          validFiles = false;
          break;
        }
      }

      if (validFiles && files.length + images.length < 10) {
        setMultipleProductImage([...multipleproductImage, ...files]);
      } else if (files.length + images.length >= 10) {
        toast.error("Sorry, you can't upload more than 10 images");
      }
    }
  };

  // Input field handler
  const inputFieldHandler = (e, type = "") => {
    const { name, value } = e.target;
    const valueType = typeof value;

    if (type === "number" && valueType === "string") {
      let updatedValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric and non-dot characters
      updatedValue = updatedValue.replace(/(\.\d{2})\d*$/, "$1"); // Restrict to 2 decimal places
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text" && valueType === "string") {
      let updatedValue = value.replace(/[^a-zA-Z]/g, ""); // Allow only alphabets
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "noOfProduct" && valueType === "string") {
      let updatedValue = value.replace(/^[^1-9]|[^0-9*]/g, "");
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "text" && valueType === "string") {
      let updatedValue = value.replace(/[^a-zA-Z]/g, ""); // Allow only alphabets
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else if (type === "sku" && valueType === "string") {
      let updatedValue = value.replace(/[^a-zA-Z0-9_ -]/g, ""); // Allow alphanumeric characters, underscores, hyphens, and spaces
      setInputFieldValue({ ...inputFieldValue, [name]: updatedValue });
    } else {
      setInputFieldValue({ ...inputFieldValue, [name]: value });
    }
  };

  // Remove Product Image
  const removeProductImage = (id) => {
    const newProductImage = [...multipleproductImage];
    newProductImage.splice(id, 1);
    setMultipleProductImage(newProductImage);
  };

  //   Delete Image Handler
  const deleteImageHandler = async (productImageId) => {
    try {
      const response = await deleteProductImageController(
        productId,
        productImageId
      );
      if (response?.code === 200) {
        fetchingProductDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Edit Product
  const editProductHandler = async (e) => {
    e.preventDefault();
    const status = inputFieldValidation();
    if (status) {
      try {
        setSubmitButton(true);
        const formData = new FormData();
        formData.append("productName", inputFieldValue?.productName || "");
        formData.append("vendorId", inputFieldValue?.vendorId || "");
        formData.append(
          "compositionName",
          inputFieldValue.compositionName || ""
        );
        formData.append("units", inputFieldValue.units || "");
        formData.append("noOfStock", inputFieldValue.noOfStock || "");
        formData.append(
          "noOfUnitPerPack",
          inputFieldValue.noOfUnitPerPack || ""
        );
        formData.append("margin", inputFieldValue.margin || "");
        formData.append(
          "marginPerUnit",
          inputFieldValue.margin && inputFieldValue.noOfUnitPerPack
            ? formatValue(
                inputFieldValue.margin / inputFieldValue.noOfUnitPerPack
              )
            : ""
        );
        formData.append(
          "productComposition",
          inputFieldValue.productComposition
        );
        formData.append("dimestion", inputFieldValue.dimestion || "");
        formData.append("smallestUnit", inputFieldValue.smallestUnit || "");
        formData.append("mrp", inputFieldValue.mrp || "");
        formData.append(
          "mrpPerUnit",
          inputFieldValue.mrp && inputFieldValue.noOfUnitPerPack
            ? formatValue(inputFieldValue.mrp / inputFieldValue.noOfUnitPerPack)
            : ""
        );
        formData.append("netPrice", inputFieldValue.netPrice);
        formData.append(
          "netPricePerUnit",
          inputFieldValue.netPrice && inputFieldValue.noOfUnitPerPack
            ? formatValue(
                inputFieldValue.netPrice / inputFieldValue.noOfUnitPerPack
              )
            : ""
        );
        formData.append("sku", inputFieldValue.sku);
        formData.append("productZohoId", productId || "");
        multipleproductImage.forEach((item, index) => {
          formData.append("productImage", item);
        });

        const response = await updateProductController(formData);
        // console.log(response, "----->Add product response");
        if (response?.code === 200) {
          toast.success(response?.message);
          setSubmitButton(false);
          navigate(-1);
        } else {
          toast.error(response?.message);
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
      }
    }
  };

  // For Format Value
  const formatValue = (value) => {
    let formattedValue = "";
    const floatValue = parseFloat(value);
    const roundedValue = floatValue.toFixed(2);
    formattedValue = roundedValue;
    return formattedValue;
  };

  useEffect(() => {
    fetchingVendorList();
    fetchingProductDetail();
  }, []);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h2 className="page-title">
                <span
                  className="page-title-icon bg-gradient-primary text-white me-2"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="mdi mdi-chevron-double-left"></i>
                </span>
                Edit Products
              </h2>
              <nav aria-label="breadcrumb"></nav>
              <div className="button_wrap">
                <button
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={fetchProductDetails}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Get
                  Reference
                </button>
              </div>
            </div>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form className="form-sample">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Product Name <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={45}
                              className="form-control"
                              name="productName"
                              placeholder="Enter Product Name"
                              value={
                                inputFieldValue?.productName
                                  ? inputFieldValue?.productName
                                  : ""
                              }
                              onChange={inputFieldHandler}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors?.productName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Vendors <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <select
                              className="form-control"
                              onChange={inputFieldHandler}
                              value={inputFieldValue?.vendorId}
                              name="vendorId"
                            >
                              <option value="">Select Vendor</option>
                              {vendorList.length > 0 &&
                                vendorList.map(({ id, name }) => {
                                  return (
                                    <React.Fragment key={id}>
                                      <option value={id}>{name}</option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                            <span style={{ color: "red" }} className="error">
                              {errors.vendorId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Composition Name
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={45}
                              className="form-control"
                              name="compositionName"
                              placeholder="Enter Composition Name"
                              value={
                                inputFieldValue?.compositionName
                                  ? inputFieldValue?.compositionName
                                  : ""
                              }
                              onChange={inputFieldHandler}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors?.compositionName}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of Stock <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfStock"
                              type="text"
                              placeholder="Enter No. of stocks"
                              maxLength={7}
                              className="form-control"
                              value={
                                inputFieldValue?.noOfStock
                                  ? inputFieldValue?.noOfStock
                                  : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "noOfProduct");
                              }}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfStock}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            SKU <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={50}
                              className="form-control"
                              name="sku"
                              placeholder="Enter SKU   (e.g:ABC123)"
                              value={
                                inputFieldValue.sku ? inputFieldValue.sku : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "sku");
                              }}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.sku}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Packaging <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={11}
                              className="form-control"
                              name="dimestion"
                              placeholder="NNNxNNNxNNN"
                              value={
                                inputFieldValue.dimestion
                                  ? inputFieldValue.dimestion
                                  : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "dimension");
                              }}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.dimestion}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Units
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              placeholder="Enter units in Kilogram,Litre"
                              className="form-control"
                              name="units"
                              value={
                                inputFieldValue?.units
                                  ? inputFieldValue?.units
                                  : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "text");
                              }}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.units}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Smallest Unit <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              placeholder="Enter smallest unit in kg,ltr"
                              name="smallestUnit"
                              value={
                                inputFieldValue?.smallestUnit
                                  ? inputFieldValue?.smallestUnit
                                  : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "text");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.smallestUnit}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={8}
                              className="form-control"
                              name="margin"
                              placeholder="Enter Margin"
                              value={calculatedMargin.toString()}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                              disabled
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.margin}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Margin Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength="5"
                              disabled
                              className="form-control"
                              name="marginPerUnit"
                              value={
                                inputFieldValue?.margin &&
                                inputFieldValue?.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue?.margin /
                                        inputFieldValue?.noOfUnitPerPack
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            MRP <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={8}
                              className="form-control"
                              name="mrp"
                              placeholder="Enter MRP"
                              value={inputFieldValue?.mrp}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.mrp}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            MRP Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength="5"
                              disabled
                              className="form-control"
                              name="mrpPerUnit"
                              value={
                                inputFieldValue?.mrp &&
                                inputFieldValue?.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue?.mrp /
                                        inputFieldValue?.noOfUnitPerPack
                                    )
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={8}
                              className="form-control"
                              name="netPrice"
                              placeholder="Enter Net Price"
                              value={inputFieldValue?.netPrice}
                              onChange={(e) => {
                                inputFieldHandler(e, "number");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.netPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Net Price Per Unit
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={5}
                              disabled
                              className="form-control"
                              name="netPricePerUnit"
                              value={
                                inputFieldValue?.netPrice &&
                                inputFieldValue?.noOfUnitPerPack
                                  ? formatValue(
                                      inputFieldValue?.netPrice /
                                        inputFieldValue?.noOfUnitPerPack
                                    )
                                  : ""
                              }
                              // onChange={(e) => {
                              //   inputFieldHandler(e, "number");
                              // }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.netPricePerUnit}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            No. of units per pack{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-sm-9 text-start">
                            <input
                              name="noOfUnitPerPack"
                              type="text"
                              placeholder="Enter No. of units"
                              maxLength={7}
                              className="form-control"
                              value={
                                inputFieldValue?.noOfUnitPerPack
                                  ? inputFieldValue?.noOfUnitPerPack
                                  : ""
                              }
                              onChange={(e) => {
                                inputFieldHandler(e, "noOfProduct");
                              }}
                            />
                            <span style={{ color: "red" }} className="error">
                              {errors.noOfUnitPerPack}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">GST</label>
                          <div className="col-sm-9 text-start">
                            <input
                              type="text"
                              maxLength={5}
                              className="form-control"
                              disabled
                              name="gst"
                              value={inputFieldValue.gstPercentage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label cstm_width">
                            Product Description
                          </label>
                          <div className="col-sm-10 cstm_width2">
                            <textarea
                              type="text"
                              className="form-control"
                              rows="4"
                              placeholder="Write here product description..."
                              name="productComposition"
                              onChange={inputFieldHandler}
                              value={inputFieldValue?.productComposition || ""}
                            />
                          </div>
                          <span style={{ color: "red" }} className="error">
                            {errors?.productComposition}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row cstm_position_set">
                      <div className="col-sm-12">
                        <div className="form-group cstm_wrap_position">
                          <label>
                            Product Image <span className="text-danger">*</span>
                          </label>

                          <div className="">
                            <input
                              ref={uploadProductImage}
                              type="file"
                              name="productImage"
                              multiple
                              className="form-control file-upload-info"
                              hidden
                              placeholder="Upload Image"
                              accept=".jpg"
                              onChange={multipeImageHandler}
                            />
                            <span
                              style={{ color: "red" }}
                              className="error cstm_ere"
                            >
                              {errors.productImage}
                            </span>

                            <div className="upload-button-wrapper">
                              <span className="input-group-append cstm_append_group">
                                <button
                                  className="file-upload-browse btn btn-gradient-primary"
                                  type="button"
                                  onClick={() =>
                                    uploadProductImage?.current?.click()
                                  }
                                >
                                  Upload
                                </button>
                              </span>
                              <small
                                className="text-danger mt-1"
                                style={{ fontSize: "10px" }}
                              >
                                (Max file size: 1MB)
                              </small>
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 mt-4">
                          <div className="row">
                            {multipleproductImage?.length > 0
                              ? multipleproductImage?.map((image, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <div className="col-3 cstm_upload_image">
                                        <span>
                                          <i
                                            className="mdi mdi-close-circle-outline cstm_close_icon"
                                            onClick={() =>
                                              removeProductImage(index)
                                            }
                                          ></i>
                                        </span>
                                        <img
                                          src={URL.createObjectURL(image)}
                                          className="mb-3  mw-100 w-100 rounded"
                                          alt="banner"
                                          height="150px"
                                          width="200px"
                                        />
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                              : null}
                          </div>

                          <div className="row">
                            {images.map(({ productImage, id }, index) => {
                              return (
                                <React.Fragment>
                                  <div className="col-3 col-3 cstm_upload_image">
                                    <span
                                      onClick={() => deleteImageHandler(id)}
                                    >
                                      <i className="mdi mdi-close-circle-outline cstm_close_icon"></i>
                                    </span>
                                    <img
                                      src={productImage}
                                      className="mb-2 mw-100 w-100 rounded"
                                      alt={inputFieldValue?.productName}
                                      height="150px"
                                      width="200px"
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      ref={submitButton}
                      className="btn btn-gradient-primary mb-2"
                      onClick={(e) => editProductHandler(e)}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditProduct;
