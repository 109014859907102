import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  orderListController,
  ownershipTypeController,
  retailerDetailController,
} from "../../services/retailerServices/retailerServices";
import { toast } from "react-toastify";
import { dateFormatYear, showDateFormatYear, subtractAndFormatDate } from "../../utility/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalBox from "../../common/ModalBox";
// import { permissionChecker } from "../../config/roleAccess";
// import { usePermission } from "../../context/create_Context";

function ViewRetailer() {
  const { id } = useParams();
  const [status, setStatus] = useState("1");
  const [retailerDetail, setRetailerDetail] = useState({});
  const [licenceDetail, setLicenceDetail] = useState([]);
  const [ownershipList, setOwnershipList] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState("");
  const [orderList, setOrderList] = useState();
  const navigate = useNavigate();
  // const {permission}=usePermission();

  const fetchRetailerDetail = async () => {
    try {
      const data = await retailerDetailController(id);
      if (data?.code === 200) {
        // toast.success(data?.message);
        setRetailerDetail(data?.data);
        setLicenceDetail(data.data?.LicenceDetails);
      } else {
        toast.error(data?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  // Fetching Ownership Type
  const fetchingOwnershipType = async () => {
    try {
      const response = await ownershipTypeController();
      console.log(response);
      if (response?.code === 200) {
        setOwnershipList(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Fetching Order Details
  const orderListHandler = async () => {
    const response = await orderListController(id, status);
    console.log(response, "orderList response");
    if (response?.code === 200) {
      setOrderList(response?.data);
    } else {
      console.log(response?.message);
    }
  };

  useEffect(() => {
    fetchRetailerDetail();
    fetchingOwnershipType();
  }, []);

  useEffect(() => {
    orderListHandler();
  }, [status]);
  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <h2 className="page-title">
              <span
                className="page-title-icon bg-gradient-primary text-white me-2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="mdi mdi-chevron-double-left"></i>
              </span>
              Retailer Detail
            </h2>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="wrap_Add_retailer">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Personal Details</h4>
                  <form className="form-sample">
                    {/* <p className="card-description"> Personal info </p> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Retailer Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              readOnly
                              value={retailerDetail.retailerName}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Aadhar Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={12}
                              className="form-control"
                              name="aadharNumber"
                              readOnly
                              value={retailerDetail.aadharNumber}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Email Address
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              readOnly
                              value={retailerDetail.email}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Phone Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="phoneNumber"
                              readOnly
                              value={retailerDetail.mobileNumber}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Secondary Phone Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="secondaryMobileNumber"
                              readOnly
                              value={retailerDetail.secondaryMobileNumber}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4 className="card-title">Business Details</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Store Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="shopName"
                              readOnly
                              value={retailerDetail?.BussinessDetail?.shopName}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Shop Location
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="shopLocation"
                              readOnly
                              value={retailerDetail.shopLocation}
                              // onChange={inputFieldHandler}
                            />
                          </div>
                        </div>
                      </div> */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             Address Line 1
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="address1"
                                value={retailerDetail?.BussinessDetail?.address1 || ""}
                                disabled
                              />
            
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             Address Line 2
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="address2"
                                value={retailerDetail?.BussinessDetail?.address2 || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             City
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={retailerDetail?.BussinessDetail?.city || ""}
                                disabled
                              />
                            
                            </div>
                          </div>
                          <div className="col-md-6">
                            
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                             State
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={retailerDetail?.BussinessDetail?.state || ""}
                                disabled
                              />
                              
                            </div>
                          </div>
                          </div>
                          <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                               pinCode
                            </label>
                            <div className="col-sm-9 text-start">
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                value={retailerDetail?.BussinessDetail?.pincode || ""}
                                disabled
                              />
                            </div>
                          </div>
                          </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Ownership Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              className="form-control"
                              disabled
                              name="ownershipType"
                              value={retailerDetail?.BussinessDetail?.type || "0"}
                            >
                               <option value="0">Select Ownership Type</option>
                              {retailerDetail?.BussinessDetail?.type ? (
                                ownershipList.map(({ id, types }) => (
                                  <option key={id} value={id}>
                                    {types}
                                  </option>
                                ))
                              ) : (
                                ""
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            GST Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="gstNumber"
                              readOnly
                              value={retailerDetail?.BussinessDetail?.gstNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Number
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              maxLength={15}
                              className="form-control"
                              name="panBussiness"
                              readOnly
                              value={
                                retailerDetail?.BussinessDetail?.panBussiness
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            PAN Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              name="panName"
                              readOnly
                              value={retailerDetail?.BussinessDetail?.panName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {retailerDetail?.BussinessDetail?.panImage ? (
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              PAN Image
                            </label>
                            <div className="col-sm-9">
                              <div className="mb-4">
                                <div className="cstm_img_set_retails">
                                  <img
                                    src={
                                      retailerDetail?.BussinessDetail?.panImage
                                    }
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="pan"
                                  />
                                </div>

                                {/* <img
                              src={retailerDetail?.BussinessDetail?.panImage }
                              className="mb-2 mw-100 w-100 rounded"
                              alt="pan"
                            /> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6">
                        {retailerDetail?.BussinessDetail?.shopImage ? (
                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label">
                              Store Image
                            </label>
                            <div className="col-sm-9">
                              <div className="mb-4">
                                <div className="cstm_img_set_retails">
                                  <img
                                    src={
                                      retailerDetail?.BussinessDetail?.shopImage
                                    }
                                    className="mb-2 mw-100 w-100 rounded"
                                    alt="pan"
                                  />
                                </div>

                                {/* <img
                              src={retailerDetail?.BussinessDetail?.shopImage}
                              className="mb-2 mw-100 w-100 rounded"
                              alt="banner"
                            /> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {licenceDetail && licenceDetail.length > 0 && (
                      <React.Fragment>
                        <h4 className="card-title">Licence Details</h4>
                        <div className="table-responsive mt-4">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Licence Name</th>
                                <th>Licence Number</th>
                                <th>Expiry Date</th>
                                <th>Licence Image</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                // licenceDetail && licenceDetail.length > 0 ? (
                                licenceDetail.map(
                                  (
                                    {
                                      licenceNumber,
                                      approvedLicenceName,
                                      expiryDate,
                                      userId,
                                      id,
                                      storeImage,
                                    },
                                    index
                                  ) => {
                                    return (
                                      <React.Fragment key={id}>
                                        <tr>
                                          <td>{++index}</td>
                                          <td>{approvedLicenceName}</td>
                                          <td>{licenceNumber}</td>
                                          <td>{showDateFormatYear(expiryDate)}</td>
                                          <td>
                                            <div className="action_button_wrap">
                                              <div className="switch_button"></div>
                                              <Link
                                                onClick={() => {
                                                  setShowImageModal(true);
                                                  setImage(storeImage);
                                                }}
                                                to="#"
                                                className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                                              >
                                                View Image
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    );
                                  }
                                )
                                // )
                                // :(
                                //   <tr>
                                //     <td colSpan={5}>No Data Found</td>
                                //   </tr>
                                // )
                              }
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    )}

                    <h4 className="card-title mt-4">Order Details</h4>
                    <div className="row p-1 border mt-4">
                      <div className="col-3">
                        <button
                          // to="#"
                          // className="btn btn-outline-success cstm_btn_wrap_theme4 me-2"
                          className={status==="1"?"btn activeTabAccepted":"btn btn-outline-success"}
                          onClick={(e) => {
                            setStatus("1");
                            e.preventDefault();
                          }}
                        >
                          Accepted
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          // to="#"
                          // className="btn btn-outline-primary cstm_btn_wrap_theme4 me-2"
                          className={status==="2"?"btn activeTabshipped":"btn btn-outline-primary"}
                          onClick={(e) => {
                            setStatus("2");
                            e.preventDefault();
                          }}
                        >
                          Shipped
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          // to="#"
                          // className="btn btn-outline-dark cstm_btn_wrap_theme4 me-2"
                          className={status==="3"?"btn activeTabdelivered":"btn btn-outline-dark"}
                          onClick={(e) => {
                            setStatus("3");
                            e.preventDefault();
                          }}
                        >
                          Delivered
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          // to="#"
                          // className="btn btn-outline-danger cstm_btn_wrap_theme4 me-2"
                          className={status==="4"?"btn activeTabcancelled":"btn btn-outline-danger"}
                          onClick={(e) => {
                            setStatus("4");
                            e.preventDefault();
                          }}
                        >
                          Cancelled
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive mt-4">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Placed On</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList && orderList.length > 0 ? (
                            orderList.map(
                              (
                                {
                                  orderNo,
                                  createdAt,
                                  totalAmount,
                                  status,
                                  userId,
                                  id,
                                },
                                index
                              ) => {
                                return (
                                  <React.Fragment>
                                    <tr>
                                      <td>{++index}</td>
                                      <td>{orderNo}</td>
                                      <td>
                                        {subtractAndFormatDate(createdAt)}
                                      </td>
                                      <td>
                                        {status === 0 && (
                                          <button className="btn-gradient-warning">
                                            Pending
                                          </button>
                                        )}
                                        {status === 1 && (
                                          <button className="btn-gradient-success">
                                            Accepted
                                          </button>
                                        )}
                                        {status === 2 && (
                                          <button className="btn-gradient-light">
                                            Shipped
                                          </button>
                                        )}
                                        {status === 3 && (
                                          <button className="btn-gradient-dark">
                                            Delivered
                                          </button>
                                        )}
                                        {status === 4 && (
                                          <button className="btn-gradient-danger">
                                            Cancelled
                                          </button>
                                        )}
                                        {status !== 0 &&
                                          status !== 1 &&
                                          status !== 2 &&
                                          status !== 3 &&
                                          status !== 4 &&
                                          " "}
                                      </td>
                                      <td>{totalAmount}</td>
                                      <td>
                                        {" "}
                                        <div className="action_button_wrap">
                                          <div className="switch_button"></div>
                                         
                                          <Link
                                            to={`/order-management/view/${id}`}
                                            className="action_button"
                                          >
                                            <VisibilityIcon
                                              className="cstm_eye"
                                              color="secondary"
                                            />
                                          </Link>
                                         
                                        </div>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={6}>Orders Not Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalBox
          status={showImageModal}
          onClose={() => {
            setShowImageModal(false);
          }}
          title={" Licence Image"}
          customizedFooter={<></>}
        >
          <React.Fragment>
            <div className="card">
              <div className="card-body">
                <form className="forms-sample">
                  {image ? (
                    <div className="form-group text-center">
                      <img src={image} height="400px" width="400px" />
                    </div>
                  ) : (
                    <div className="form-group text-center">
                      <h4>No Image Found</h4>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </React.Fragment>
        </ModalBox>
      </div>
    </React.Fragment>
  );
}

export default ViewRetailer;
