import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import CustomSwitch from "../../common/ToggleStatus";
import {
  deleteRetailerController,
  retailerListController,
  retailerStatusChangeController,
} from "../../services/retailerServices/retailerServices";
import {
  dateFormat,
  dateFormatYear,
  showDateFormatYear,
  subtractAndFormatDate,
} from "../../utility/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ModalBox from "../../common/ModalBox";
import ToggleStatus from "../../common/ToggleStatus";
import { FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import Pagination from "react-js-pagination";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { red } from "@mui/material/colors";
import useButtonLoader from "../../hooks/useButtonLoader";
import { actionsChecker, permissionChecker } from "../../config/roleAccess";
import { usePermission } from "../../context/create_Context";

const RetailerManagement = () => {
  const [retailerList, setRetailerList] = useState([]);
  const [retailerId, setRetailerId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [filterByProfile, setFilterByProfile] = useState();
  const [filterByStatus, setFilterByStatus] = useState();
  const [filterByapprove, setFilterByApprove] = useState();
  const [submitButton, setSubmitButton] = useButtonLoader();
  const [deleteReason, setDeleteReason] = useState("");
  const [errors, setErrors] = useState("");

  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [startNumber, setStartNumber] = useState(1);
  const [totalList, setTotalList] = useState();

  const debounceValue = useDebounce(search, 500);
  const [loader, show, hide] = useFullPageLoader();

  const { permission } = usePermission();

  //Fetching Retailer List
  const fetchingRetailerManagementList = async () => {
    try {
      show();
      const response = await retailerListController(
        activePage,
        filterByProfile ? filterByProfile : "",
        filterByapprove ? filterByapprove : "",
        filterByStatus ? filterByStatus : "",
        debounceValue ? debounceValue : "",
        filterByDate ? dateFormatYear(filterByDate) : "",
        limit
      );
      if (response.code === 200) {
        hide();
        setRetailerList(response?.data?.rows);
        // console.log(response.data.rows);
        setTotalList(response?.data?.count);
      } else {
        toast.error(response.message);
        hide();
      }
    } catch (e) {
      console.log(e.message);
      hide();
    }
  };

  // Fetching Retailer Status Change Handler
  const retailerStatusHandler = async () => {
    try {
      setSubmitButton(true);
      const response = await retailerStatusChangeController(retailerId);
      setSubmitButton(false);
      if (response?.code === 200) {
        setStatusModal(false);
        toast.success(response.message);
        fetchingRetailerManagementList();
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // Delete Input Field Validation
  const validation = () => {
    let validationStatus = true;
    let error = "";
    if (!deleteReason) {
      error = "Reason is Mandatory *";
      validationStatus = false;
    }
    setErrors(error);
    return validationStatus;
  };

  // Fetching Delete Retailer
  const deleteRetailerHandler = async (e) => {
    e.preventDefault();
    const status = validation();
    if (status) {
      setSubmitButton(true);
      try {
        const data = {
          userId: retailerId,
          deleteReason: deleteReason,
        };

        const response = await deleteRetailerController(data);
        if (response?.data?.code === 200) {
          setDeleteModal(false);
          setDeleteReason("");
          toast.success(response.data?.message);
          fetchingRetailerManagementList();
          setSubmitButton(false);
        } else {
          toast.error(
            response?.response?.data?.message || response?.data?.message
          );
          setDeleteModal(false);
          setErrors("");
          setDeleteReason("");
          setSubmitButton(false);
        }
      } catch (e) {
        console.log(e.message);
        setSubmitButton(false);
        setDeleteReason("");
      }
    }
  };

  //*Handle Pagination*
  const handlePageChange = (event) => {
    setActivePage(event);
  };

  //Clear Filter
  const clearFilter = () => {
    setFilterByApprove("");
    setFilterByDate("");
    setFilterByProfile("");
    setFilterByStatus("");
    setActivePage(1);
    setLimit("10");
    setSearch("");
    fetchingRetailerManagementList();
  };

  useEffect(() => {
    fetchingRetailerManagementList();
  }, [
    filterByProfile,
    filterByStatus,
    filterByapprove,
    filterByDate,
    debounceValue,
    activePage,
    limit,
  ]);

  useEffect(() => {
    setStartNumber((activePage - 1) * limit + 1);
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="page-header">
            <div className="page_title_wrap">
              <h3 className="page-title"> Retailer Management </h3>
              <div className="button_wrap">
                {permissionChecker(permission, 2, 6) && (
                  <Link
                    to="/retailer-requests-list"
                    className="btn btn-outline-primary cstm_btn_wrap_theme me-2"
                  >
                    Requests
                  </Link>
                )}

                {permissionChecker(permission, 2, 1) && (
                  <Link
                    to="/add-retailer"
                    className="btn btn-outline-info cstm_btn_wrap_theme"
                  >
                    <i className="mdi mdi-plus menu-icon menu-icon"></i> Add
                    Retailer
                  </Link>
                )}
                <Link
                  to="/retailer-management/delete-retailer-detail"
                  className="btn btn-outline-dark cstm_btn_wrap_theme m-2"
                >
                  <i className="mdi mdi-plus menu-icon menu-icon"></i> Delete
                  Requests
                </Link>

                <Link
                  to="#"
                  className="btn btn-outline-success cstm_btn_wrap_theme mx-2"
                  onClick={clearFilter}
                >
                  <i className="mdi mdi-refresh menu-icon menu-icon"></i> Reset
                </Link>
              </div>
            </div>
          </div>

          {/* Search bar  */}
          <div className="row wrap_selectCstm">
            <div className="col-1 cstm_padding_set">
              <select
                className="pageSelect input"
                onChange={(e) => setLimit(e.target.value)}
                value={limit}
              >
                <option value={"10"}>10</option>
                <option value={"25"}>25</option>
                <option value={"50"}>50</option>
                <option value={"100"}>100</option>
              </select>
            </div>
            <div className="col-lg-3 cstm_padding_set">
              <div className="search_box">
                <i className="mdi mdi-magnify"></i>
                <input
                  type="text"
                  className="form-control search cstm_btn_wrap_theme"
                  placeholder="Search by name,email address or Phone Number"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-2 cstm_padding_set">
              <div className="datePicker_wrap cstm_date_picker">
                <DatePicker
                  showIcon
                  placeholderText="Filter By Date"
                  icon={true}
                  className="input date_picker cstm_btn_wrap_theme2 "
                  selected={filterByDate}
                  value={filterByDate}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  maxDate={Date.now()}
                  onChange={(date) => setFilterByDate(date)}
                  dateFormat="dd/MM/yyyy"
                  onChangeRaw={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="col-lg-2 cstm_padding_set">
              <select
                className="btn btn-gradient-primary cstm_btn_wrap_theme2 wrap_select"
                onChange={(e) => setFilterByProfile(e.target.value)}
                value={filterByProfile}
              >
                <option value="">Profile Status</option>
                <option value={0}>Pending</option>
                <option value={1}>Incomplete</option>
                <option value={2}>Complete</option>
              </select>
            </div>
            <div className="col-2 cstm_padding_set">
              <select
                className=" btn btn-gradient-primary cstm_btn_wrap_theme2 wrap_select"
                onChange={(e) => setFilterByApprove(e.target.value)}
                value={filterByapprove}
              >
                <option value="">Account Status</option>
                <option value={0}>Unverified</option>
                <option value={1}>Verified</option>
                <option value={2}>Reject</option>
              </select>
            </div>
            <div className="col-2 cstm_padding_set">
              <select
                className="btn btn-gradient-primary cstm_btn_wrap_theme2 wrap_select"
                onChange={(e) => setFilterByStatus(e.target.value)}
                value={filterByStatus}
              >
                <option value="">Status</option>
                <option value={0}>Inactive</option>
                <option value={1}>Active</option>
              </select>
            </div>
          </div>
          {loader}

          {/* DropDown for approved Status */}

          <div className="row wrap_selectCstm">
            <div className="col-lg-12 grid-margin stretch-card cstm_padding_set">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Retailer Name</th>
                          <th>Email</th>
                          <th>Mobile Number</th>
                          <th>Created</th>
                          <th>Profile Status</th>
                          <th>Status</th>
                          {actionsChecker(permission, 2) && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {retailerList && retailerList.length > 0 ? (
                          retailerList?.map(
                            (
                              {
                                id,
                                retailerName,
                                email,
                                mobileNumber,
                                createdAt,
                                status,
                                isApproved,
                                isProfileCompleted,
                              },
                              index
                            ) => {
                              return (
                                <React.Fragment key={++index}>
                                  <tr>
                                    <td>
                                      {(activePage - 1) * limit + ++index}
                                    </td>
                                    <td>
                                      {retailerName ? retailerName : "---"}
                                    </td>
                                    <td>{email ? email : "---"}</td>
                                    <td>
                                      {mobileNumber ? mobileNumber : "---"}
                                    </td>
                                    <td>
                                      {createdAt
                                        ? showDateFormatYear(createdAt)
                                        : "---"}
                                    </td>
                                    <td>
                                      {isProfileCompleted === 0 ? (
                                        <button className="btn-gradient-warning">
                                          Pending
                                        </button>
                                      ) : isProfileCompleted === 1 ? (
                                        <button className="btn-gradient-danger">
                                          Incomplete
                                        </button>
                                      ) : isProfileCompleted === 2 ? (
                                        <button className="btn-gradient-success">
                                          Complete
                                        </button>
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                    <td>
                                      {isApproved === 0 ? (
                                        <button className="btn-gradient-warning">
                                          Unverified
                                        </button>
                                      ) : isApproved === 1 ? (
                                        <button className="btn-gradient-success">
                                          Verified
                                        </button>
                                      ) : isApproved === 2 ? (
                                        <button className="btn-gradient-danger">
                                          Reject
                                        </button>
                                      ) : (
                                        "---"
                                      )}
                                    </td>
                                    {actionsChecker(permission, 2) && (
                                      <td>
                                        <div className="action_button_wrap">
                                          {permissionChecker(
                                            permission,
                                            2,
                                            5
                                          ) && (
                                            <div className="switch_button">
                                              <FormControlLabel
                                                control={
                                                  <ToggleStatus
                                                    onClick={() => {
                                                      setStatusModal(true);
                                                      setRetailerId(id);
                                                    }}
                                                    sx={{ m: 1 }}
                                                    checked={status == 1}
                                                  />
                                                }
                                              />
                                            </div>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            2,
                                            3
                                          ) && (
                                            <Link
                                              to={`/view-retailer/${id}`}
                                              className="action_button"
                                            >
                                              <VisibilityIcon
                                                className="cstm_eye"
                                                color="secondary"
                                              />
                                            </Link>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            2,
                                            2
                                          ) && (
                                            <Link
                                              to={`/edit-retailer/${id}`}
                                              className="action_button"
                                            >
                                              <i className="mdi mdi-table-edit"></i>
                                            </Link>
                                          )}
                                          {permissionChecker(
                                            permission,
                                            2,
                                            4
                                          ) && (
                                            <Link className="action_button">
                                              <DeleteRoundedIcon
                                                sx={{ color: red[500] }}
                                                onClick={() => {
                                                  setRetailerId(id);
                                                  setDeleteModal(true);
                                                }}
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                </React.Fragment>
                              );
                            }
                          )
                        ) : (
                          <tr>
                            <td colSpan={8}><strong>No Data Found</strong></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>
                        Showing {startNumber} to{" "}
                        {startNumber + retailerList?.length - 1} of {totalList}{" "}
                        entries
                      </h5>
                    </div>
                    {totalList > 10 ? (
                      <div className="pagination_wrap">
                        <Pagination
                          activePage={activePage}
                          linkClass="page-link"
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          itemsCountPerPage={limit}
                          totalItemsCount={totalList}
                          pageRangeDisplayed={5}
                          itemClassLast="last_page next"
                          itemClassFirst="first_page prev"
                          activeLinkClass="active"
                          onChange={handlePageChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* Modal for Status Change */}
            <ModalBox
              status={statusModal}
              onClose={() => setStatusModal(false)}
              title={"Change Status"}
              description="Are you sure you want to change status of this retailer?"
              action={retailerStatusHandler}
              uploadButtonElement={submitButton}
            />

            {/* Cancel Reason Modal*/}
            <ModalBox
              status={deleteModal}
              onClose={() => {
                setDeleteModal(false);
                setDeleteReason("");
                setErrors("");
              }}
              title="Delete Retailer"
              customizedFooter={<></>}
            >
              <React.Fragment>
                <div className="card">
                  <div className="card-body">
                    <form className="forms-sample">
                      <div className="form-group">
                        <label htmlFor="Banner ">
                          Enter Delete Reason{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="exampleInputName1"
                          cols={4}
                          value={deleteReason}
                          name="cancelReason"
                          placeholder="Enter delete reason"
                          onChange={(e) => setDeleteReason(e.target.value)}
                        />
                        <span style={{ color: "red" }} className="error">
                          {errors}
                        </span>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-gradient-primary me-2"
                            onClick={(e) => {
                              deleteRetailerHandler(e);
                            }}
                            ref={submitButton}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={(e) => {
                              setDeleteModal(false);
                              e.preventDefault();
                              setDeleteReason("");
                              setErrors("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </React.Fragment>
            </ModalBox>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RetailerManagement;
