import {
  instance,
  multipartJwtToken,
  setJwtToken,
} from "../../config/axiosInstance";
import { toast } from "react-toastify";


export const fetchProductByIdController = async (itemId) => {
  try {
    const response = await instance.get(`admin/zoho-product-info?zohoProductId=${itemId}`, {
      ...setJwtToken(),
    });

    console.log('API Response:', response);
    const responseData = response.data;

    if (responseData.code === 200) {
      return responseData.data;
    } else {
      return {
        success: false,
        product: null,
        message: responseData?.message || 'Failed to find product',
      };
    }
  } catch (error) {
    toast.error(error.response?.data?.message || 'An error occurred while fetching the product');
    console.error('Error in fetchProductByIdController:', error);
    return {
      success: false,
      product: null,
      message: error.response?.data?.message || 'An error occurred while fetching the product',
    };
  }
};





// Fetching Product List Api
export const productListController = async (
  activePage,
  limit,
  search,
  date
) => {
  const params = {};
  params.search = search;
  params.page = activePage ? activePage : "";
  params.limit = limit ? limit : "";
  params.date = date ? date : "";
  const data = await instance.get(`admin/product-list`, {
    params,
    ...setJwtToken(),
  });
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching  Add Product  Api
export const addProductController = async (reqData) => {
  const data = await instance.post(
    `admin/create-product`,
    reqData,
    multipartJwtToken()
  );
  if (data?.data?.code) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Sync Database and Zoho Products
export const syncProductController = async () => {
  try {
    const headers = multipartJwtToken();
    console.log("Request Headers:", headers);
    const response = await instance.put('admin/sync-product-list', null, {
      ...headers,
    });
          //  console.log('res', response)
    if (response?.data?.code) {
      // toast.success("Product list updated successfully from Zoho!");
      return response?.data;
    } else {
      toast.error("Failed to update product list from Zoho.");
    }
  } catch (error) {
    toast.error("An error occurred while updating the product list from Zoho.");
    console.error("Error in syncProductController:", error);
  }
};



// Change Product Status API
export const changeProductStatusController = async (id) => {
  const data = await instance.post(
    `admin/update-product-status`,
    { id: id },
    setJwtToken()
  );
  if (data?.data?.code) {
    return data.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching  Update Product  Api
export const updateProductController = async (reqData) => {
  const data = await instance.post(
    `admin/update-product`,
    reqData,
    multipartJwtToken()
  );
  if (data.data.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching Delete Images That Comes In Edit Product API

export const deleteProductImageController = async (id, imageId) => {
  const data = await instance.post(
    `admin/delete-product-image`,
    { productId: id, productImgId: imageId },
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};

// Fetching Product Details  Api
export const productDetailsController = async (id) => {
  const data = await instance.get(
    `admin/product-detail?productZohoId=${id}`,
    setJwtToken()
  );
  if (data?.data?.code === 200) {
    return data?.data;
  } else {
    toast.error(data?.response?.data?.message || data?.data?.message);
    return data?.data;
  }
};
